import React, { Fragment } from "react";
import { connect } from "react-redux";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import animationSuccess from "../CheckoutResult/success_payment.json";
import { Text } from "../../../../../shared-components/my-scope/atoms/text";
import { Link } from "react-router-dom";
import "../Checkout/checkoutPage.scss";
import "./assessmentSuccessSubmit.scss";
import Lottie from "react-lottie";
import { getSurvay } from "../../../actions/SAformActions";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PopUp from "../../Components/PopUp/PopUp";
import { ADDITIONAL_ASSESSMENT } from "../../../common/constants/paymentStatuses";
import {
  ASSESSMENT_TYPE_BASIC,
  ASSESSMENT_TYPE_RE_ASSESSMENT,
} from "../../../common/constants/assessmentStatuses";
const AssessmentSuccessSubmit = (props) => {
  const {
    period,
    periodId,
    assessmentId,
    establishmentProfileId,
    assessmentVersion,
  } = props.match.params;
  const { assessment_type } = props.location.state;
  const isOpen = assessment_type === ASSESSMENT_TYPE_BASIC ? true : false;
  const [isShowPopUp, setIsShowPopUp] = useState(isOpen);

  const { onClickAction, exit } = props;
  const [link, setLink] = useState();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleGetSurvey = async () => {
    const res = await getSurvay(establishmentProfileId);
    setLink(res?.url);
  };
  useEffect(() => {
    handleGetSurvey();
  }, [establishmentProfileId]);

  return (
    <PageWrapper>
      <PopUp
        isOpen={isShowPopUp}
        isCustomPopUpModel="window-width"
        exit
        title=" إستبيان تقييم مستوى الخدمة"
        onClickCancel={() => {
          setIsShowPopUp(false);
        }}
        closeIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.981"
            height="13.572"
            viewBox="0 0 13.981 14.572"
          >
            <path
              id="Shape"
              d="M12.274,14.266,7,8.764l-5.29,5.5a.971.971,0,0,1-1.414,0,1.072,1.072,0,0,1,0-1.473l5.29-5.5L.3,1.778A1.073,1.073,0,0,1,.3.3a.973.973,0,0,1,1.415,0L7,5.818,12.274.331a.971.971,0,0,1,1.414,0,1.072,1.072,0,0,1,0,1.473L8.412,7.292l5.277,5.5a1.073,1.073,0,0,1,0,1.473.971.971,0,0,1-1.414,0Z"
              fill="#92929d"
            />
          </svg>
        }
        boxStyle={{ maxWidth: "850px" }}
        bodyLink={
          <iframe
            src={link}
            style={{
              overflow: "auto",
              width: "100%",
              maxWidth: "820px",
              height: "500px",
            }}
          />
        }
      ></PopUp>
      <Fragment>
        <div className="assessmnet-success-submit assessmnet-success-submit__padding checkout-page-payment-card checkout-page-payment-success">
          <Lottie options={defaultOptions} height={200} width={200} />
          {assessment_type === ASSESSMENT_TYPE_RE_ASSESSMENT ? (
            <Text
              Tag="div"
              textType="h6"
              className="checkout-page-payment-success checkout-page-payment-success__text"
            >
              نشكرك على إعادة التقييم، سوف يتم معالجة طلبك قريبًا، يمكنك الإطلاع
              على النتيجة المبدئية من خلال صفحة نتيجة التقييم
            </Text>
          ) : (
            <Text
              Tag="div"
              textType="h6"
              className="checkout-page-payment-success checkout-page-payment-success__text"
            >
              نشكرك على المسارعة لإكمال التقييم، سوف يتم معالجة طلبك قريبًا،
              يمكنك الإطلاع على النتيجة المبدئية من خلال صفحة نتيجة التقييم
            </Text>
          )}
          <Link
            to={`/establishmentAssessmentResult/${periodId}/${assessmentId}/${establishmentProfileId}/${assessmentVersion}`}
            className="checkout-page-payment-success__link"
          >
            <Text Tag="div" textType="h7">
              الانتقال لصفحة نتيجة التقييم
            </Text>
          </Link>
        </div>
      </Fragment>
    </PageWrapper>
  );
};
AssessmentSuccessSubmit.propTypes = {
  getSurvay: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSurvay: (establishmentProfileId) =>
    dispatch(getSurvay(establishmentProfileId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentSuccessSubmit);
