import React, { useCallback, useEffect, useRef, useState } from "react";
// import { Text } from "../../../lib/components/index";
import { TermSection } from "../term-section";
import {
  useActiveStep,
  useCalibers,
  usePeriod,
  useSetCalibers,
  useSteps,
} from "../../self-assessment-form-context/index";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import request from "../../../../../../common/request";
import Loader from "../../../../../../components/Loader/Loader";
import { ButtonsRow } from "../buttons-row";
import { QiwaCaliberCard } from "../../../../../../../../frontend-audit/src/components/audit_form/QiwaCaliberCard";
import { AddonsWithAgreements } from "../addons/addons-with-agreements";
import {
  DETAILEDREPORT,
  GRACE_PERIOD,
  DETAILED_REPORT_WITH_GRACE_PERIOD,
  RE_ASSESSMENT,
} from "../../../../../../common/constants/paymentStatuses";
import toastr from "toastr";
import PopUp from "../../../../../Components/PopUp/PopUp";
import {
  loadPaymentServicesByTypeSuccess,
  loadPaymentServicesSuccess,
} from "../../../../../../actions/establishmentProfileActions";
import { useDispatch } from "react-redux";
import "./step-container.css";
import { Text } from "../../../../../../../../shared-components/my-scope/atoms/text";
export const StepContainer = ({ history }) => {
  const [errors, setErrors] = useState({});
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const dispatch = useDispatch();

  // const styles = useSpring({
  //   from: {
  //     opacity: -1,
  //     y: 50
  //   },
  //   to: {
  //     opacity: 1,
  //     y: 0
  //   },
  //   config: {
  //     duration: 750,
  //   }
  // })
  const establishmentProfile = useSelector(
    (state) => state?.establishment.establishmentProfile
  );
  const establishmentProfileId = establishmentProfile.id;
  const serviceList = services.filter(
    (item) => item.type === DETAILEDREPORT || item.type === GRACE_PERIOD
  );

  const calibers = useCalibers();
  const setCalibers = useSetCalibers();
  const activeStep = useActiveStep();
  const period = usePeriod();
  const steps = useSteps();
  const step = useMemo(() => {
    let caliber;
    const catgeory = steps?.[activeStep.index];
    if (catgeory?.caliber_categories?.[activeStep.categoryIndex]) {
      if (
        catgeory?.caliber_categories?.[activeStep.categoryIndex]?.calibers?.[
          activeStep.caliberIndex
        ]
      ) {
        caliber =
          catgeory?.caliber_categories?.[activeStep.categoryIndex]?.calibers?.[
            activeStep.caliberIndex
          ];
      } else {
        caliber = catgeory?.caliber_categories?.[activeStep.categoryIndex];
      }
    } else if (catgeory?.items || catgeory?.type) {
      caliber = catgeory;
    } else {
      caliber = catgeory?.calibers?.[activeStep.caliberIndex];
    }
    return caliber;
  }, [activeStep, steps]);

  const fetchServices = async () => {
    const url = `/payments/services/${establishmentProfileId}`;
    const res = await request(url).then((res) => res.json());
    dispatch(loadPaymentServicesSuccess(res ? res : []));
    dispatch(loadPaymentServicesByTypeSuccess(res, false));

    setServices(res);
  };
  const fetchCaliberDetails = useCallback(async (url, id) => {
    setIsLoading(true); // Set isLoading to true before fetching data
    try {
      const res = await request(url).then((res) => res.json());
      setCalibers({ [id]: res });
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data
    }
  }, []);
  useEffect(() => {
    if (steps?.[activeStep?.index]?.type === "addons") fetchServices();
  }, [establishmentProfileId, period]);

  useEffect(() => {
    const assessmentVersion = period.assessment_version;
    const periodsAssessmentId = period.period_assessment_id;
    if (assessmentVersion && periodsAssessmentId && step?.caliber_id) {
      const url = `/v1/establishments/${establishmentProfileId}/caliber/${periodsAssessmentId}/${assessmentVersion}/${step?.caliber_id}`;
      fetchCaliberDetails(url, step?.caliber_id);
    }
  }, [fetchCaliberDetails, establishmentProfileId, period, step]);

  const refreshCaliber = async () => {
    const assessmentVersion = period.assessment_version;
    const periodsAssessmentId = period.period_assessment_id;
    if (assessmentVersion && periodsAssessmentId && step?.caliber_id) {
      const url = `/v1/establishments/${establishmentProfileId}/caliber/${periodsAssessmentId}/${assessmentVersion}/${step?.caliber_id}`;
      fetchCaliberDetails(url, step?.caliber_id);
    }
  };

  const CaliberRes = calibers[step?.caliber_id] ?? {};
  // adding ref for active step. to match it with the next one
  const activeStepRef = useRef(activeStep?.index);

  useEffect(() => {
    // index is consist of caliberIndex categoryIndex and index
    // ref should be a combination of caliberIndex and categoryIndex and index
    const currentIdx = `${activeStep?.caliberIndex}-${activeStep?.categoryIndex}-${activeStep?.index}`;
    if (activeStepRef.current !== currentIdx) {
      activeStepRef.current = currentIdx;
      const element = document.getElementById(
        "assessment-inner-context-wrapper"
      );
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      // no change here.
      // console.log('no change here')
    }
  }, [
    activeStep?.index,
    activeStep?.caliberIndex,
    activeStep?.categoryIndex,
    activeStepRef?.current,
  ]);

  const submitPaymentWithCorrection = async () => {
    const isReAssessment = period.assessment_type === RE_ASSESSMENT;
    const serviceIds = services
      .filter((service) =>
        isReAssessment ? service.type === DETAILEDREPORT : true
      )
      .filter(
        (service) =>
          service.type === DETAILEDREPORT || service.type === GRACE_PERIOD
      )
      .map((service) => service.id);
    const assessmentVersion = period?.assessment_version;
    const assessmentId = period?.assessment?.id;
    let payment = {
      payment_bill: {
        payment_service_ids: serviceIds,
        period_id: period?.id,
        assessment_id: assessmentId,
        assessment_version: Number(assessmentVersion),
      },
    };
    setIsLoading(true);

    const url = `/payments/establishments/initiate_detailed_report/${establishmentProfileId}`;
    const val = await request(url, "POST", payment)
      .then((res) => {
        setIsLoading(false);
        return res.json();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error == 424) {
          setIsShowPopUp(true);
        } else if (error && error.details) {
          toastr.error(error.details);
        } else {
          toastr.error("حدث خطأ ما");
        }
      });

    history.push(
      `/checkout/${val.hyperpay_bill.id}/${
        isReAssessment ? DETAILEDREPORT : DETAILED_REPORT_WITH_GRACE_PERIOD
      }/${val.payment_bill.sadad_number}/${
        val.payment_bill.payment_resp_bill_number
      }`
    );
  };

  const goToSuccessScreen = () => {
    const assessmentVersion = period?.assessment_version;
    const assessmentId = period?.assessment?.id;

    if (steps?.[activeStep?.index]?.isCheckedService) {
      if (
        establishmentProfile.address_country &&
        establishmentProfile.address_district &&
        establishmentProfile.address_building_number &&
        establishmentProfile.address_postal_code &&
        establishmentProfile.address_street &&
        establishmentProfile.address_city
      ) {
        submitPaymentWithCorrection();
      } else {
        setIsShowPopUp(true);
      }
    } else {
      history.push({
        pathname: `/assessmentSuccessSubmit/${period?.id}/${assessmentId}/${establishmentProfileId}/${assessmentVersion}`,
        state: {
          isOpen: true,
          assessment_type: period.assessment_type,
        },
      });
    }
  };

  if (
    (!Object.keys(CaliberRes).length &&
      steps?.[activeStep?.index]?.type !== "qiwa" &&
      steps?.[activeStep?.index]?.type !== "addons") ||
    isLoading
  ) {
    return (
      <div
        className={
          "flex-1 w-full min-h-[600px] flex items-center justify-center"
        }
      >
        <Loader />
      </div>
    );
  }
  if (steps?.[activeStep?.index]?.type === "qiwa") {
    return (
      <div className={"flex flex-col flex-1 h-full w-full gap-y-4 relative"}>
        <div
          className={
            "flex-1 flex-col w-full pr-[62px] pl-[72px] step-container-padding"
          }
        >
          <QiwaCaliberCard items={steps?.[activeStep?.index]?.items} />
        </div>

        <div
          className="mt-14 mb-14 mobile-view"
          style={{
            // marginRight: "-62px",
            // width: "100vw",
            width: "100%",
            borderWidth: "0.5px",
            borderColor: "rgb(243 244 246)",
          }}
        />

        <div className="mt-auto pr-[62px] pl-[72px]  step-container-padding mobile-buttons">
          <ButtonsRow setErrors={setErrors} step={step} />
        </div>
      </div>
    );
  }
  if (steps?.[activeStep?.index]?.type === "addons") {
    return (
      <div
        className={"flex flex-col min-h-[85vh] flex-1 h-full w-full gap-y-4"}
      >
        <AddonsWithAgreements
          isDetailedReportAllowed={
            steps?.[activeStep?.index]?.isDetailedReportAllowed
          }
          errors={errors}
          serviceList={serviceList}
          assessmentType={period.assessment_type}
        />
        <div
          className="mt-14 mb-14 mobile-view"
          style={{
            // marginRight: "-62px",
            // width: "100vw",
            width: "100%",
            borderWidth: "0.5px",
            borderColor: "rgb(243 244 246)",
          }}
        />

        <div className="mt-auto pr-[62px] pl-[72px]  step-container-padding mobile-buttons">
          <ButtonsRow
            setErrors={setErrors}
            step={step}
            goToSuccessScreen={goToSuccessScreen}
          />
        </div>
        <PopUp
          isOpen={isShowPopUp}
          title="إضافة عنوان المنشأة"
          body="لإتمام عملية الدفع يرجى إضافة العنوان الوطني للمنشأة ، و ذلك عن طريق الانتقال لصفحة ملف المنشأة."
          onClickCancel={() => setIsShowPopUp(false)}
          cancelButtonText="حسنًا"
        />
      </div>
    );
  }

  return (
    // <animated.div key={JSON.stringify(CaliberRes)} style={styles} className="flex flex-col text-start">
    <div className="flex flex-col text-start h-full">
      {/* <Text
        size="xl"
        className="text-ims-deep_sea_blue font-mol font-light pr-[62px] pl-[72px] step-container-padding	"
      > */}
      <Text
        Tag={"div"}
        textType="bold"
        fontSize={"font-20"}
        mobileFontSize={"font-16-mobile"}
        className="text-ims-deep_sea_blue font-mol font-light pr-[62px] pl-[72px] step-container-padding	"
      >
        نوع المعيار: {CaliberRes?.caliber_category_name}
      </Text>
      <Text
        Tag={"div"}
        textType="roman"
        fontSize={"font-18"}
        mobileFontSize={"font-16-mobile"}
        className="text-ims-deep_sea_blue font-semibold mt-4 pr-[62px] pl-[72px] step-container-padding"
      >
        {"شرح المعيار"}
      </Text>
      <Text
        Tag={"div"}
        textType="roman"
        fontSize={"font-18"}
        mobileFontSize={"font-16-mobile"}
        style={{ color: "#000" }}
        className=" font-effra font-extralight pr-[62px] pl-[72px] step-container-padding"
      >
        {CaliberRes?.caliber_category_description}
      </Text>
      <div className="mt-6 pb-6 pr-[62px] pl-[72px] step-container-padding">
        <TermSection
          errors={errors}
          refreshCaliber={refreshCaliber}
          step={step}
          data={CaliberRes}
        />
      </div>

      <div
        className="mt-14 mb-14 mobile-view"
        style={{
          // marginRight: "-62px",
          // width: "100vw",
          width: "100%",
          borderWidth: "0.5px",
          borderColor: "rgb(243 244 246)",
        }}
      />

      <div className="mt-auto pr-[62px] pl-[72px]  step-container-padding mobile-buttons">
        <ButtonsRow setErrors={setErrors} step={step} />
      </div>
    </div>
  );
};
