import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Text } from "../../../../../shared-components/my-scope/atoms/text/index";
import "./FastTrackCard.scss";
const FastTrackCard = (props) => {
  const { title, subtitle, content, description, image, width, height } = props;
  return (
    <div className="card-container">
      <Card className="responsive-card">
        <CardContent>
          <Text
            Tag="div"
            textType="h7"
            isBold
            className="assessment-title-fast-track"
            style={{ textAlign: "center", marginTop: 58 }}
          >
            {title}
          </Text>
          <Text Tag="div" textType="p5" className="self-assessment-item">
            <>
              {description?.map((des, index) => {
                return (
                  <>
                    <p
                      style={{
                        marginTop: index === 0 ? 0 : 26,
                      }}
                      className="fast-track-feature-card-description"
                    >
                      {subtitle[index]}
                    </p>
                    <p style={{ marginBottom: 0 }}>{description[index]}</p>
                  </>
                );
              })}
            </>
          </Text>
          <Text Tag="div" textType="p5" className="self-assessment-table__item">
            {content?.map((item) => {
              return (
                <p>
                  <span className="icon-triangle"> &#9666; </span>
                  {item}
                </p>
              );
            })}
          </Text>
          <div className="fast-track-image">
            <img src={image} className="image" />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default FastTrackCard;
