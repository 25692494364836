import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import toastr from "toastr";

import * as profileActions from "../../actions/establishmentProfileActions";
import CollectionModal from "../../components/CollectionModal/CollectionModal";
import CollectionModalItem from "../../components/CollectionModal/CollectionModalItem";

import "./page-wrapper.scss";
// eslint-disable-next-line import/default
import UserAvatar from "./user-avatar.svg";
// eslint-disable-next-line import/default
import FooterLogo from "../../../public/img/IMS-MHRSD-logo.svg";
import InsideSystemFooter from "../../../public/img/inside_system_footer.svg";
import IconHome from "./IconHome";
import IconEstablishmentProfile from "./IconEstablishmentProfile";
import IconSelfAssessment from "./IconSelfAssessment";
import IconTraining from "./IconTraining";
import IconBillsList from "./IconBillsList";
import Breadcrumbs from "./Breadcrumbs";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import HeaderNotification from "../../views/Components/HeaderNotification/HeaderNotification";
import IconPreviousAssessment from "./IconPreviousAssessment";
import IconFastTrackAssessment from "./IconFastTrackAssessment";
import { AccessiblityContainer } from "../AccessibilityContainer";
import IconLogout from "./IconLogout";

class PageWrapper extends React.Component {
  constructor(props) {
    super(props);

    const modalOpen =
      !props.establishmentProfile.labor_office_id &&
      !props.establishmentProfile.sequence_id;

    this.state = {
      sidebarOpen: false,
      modalOpen,
    };

    this.onEstablishmentChange = this.onEstablishmentChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.hideSidebar = this.hideSidebar.bind(this);
    this.showEstablishmentModal = this.showEstablishmentModal.bind(this);
    this.hideEstablishmentModal = this.hideEstablishmentModal.bind(this);
    this.logOut = this.logOut.bind(this);
    this.addMonths = this.addMonths.bind(this);
    this.isActiveAssessmentTap = this.isActiveAssessmentTap.bind(this);
  }

  componentDidMount() {
    const { isLogged, history } = this.props;

    const widget = document.getElementById("jira-widget");
    if (widget) {
      widget.style.display = "none";
    }

    if (!isLogged) {
      window.location =
        process.env.REACT_APP_ESTABLISHMENT_BASE_URI + "/#/redirect";
      window.location.reload(true);
    }

    if (localStorage.getItem("selected")) {
      this.setState({ modalOpen: false });
    }
  }

  onEstablishmentChange(establishment) {
    const { saveSelectedEstablishment, establishmentProfile, history } =
      this.props;

    saveSelectedEstablishment(establishment);
    this.hideEstablishmentModal();
    if (establishment.id !== establishmentProfile.id) {
      const isProfileFirstLogin =
        establishment.id && !establishment.is_confirmed;
      if (isProfileFirstLogin) {
        history.replace("/establishmentProfile");
      } else {
        history.replace("/establishmentBasicDurationsPage");
      }
    }
  }

  showSidebar() {
    this.setState({ sidebarOpen: true });
  }

  hideSidebar() {
    this.setState({ sidebarOpen: false });
  }

  showEstablishmentModal() {
    this.setState({ modalOpen: true });
    this.props.getEstablishments();
  }

  hideEstablishmentModal() {
    this.setState({ modalOpen: false });
  }

  logOut() {
    window.kc
      .logout()
      .success(() => {
        toastr.success("تم الخروج من النظام.");
        window.location.reload(true);
      })
      .catch((error) => {
        toastr.error(error);
      });
  }

  addMonths(date, months) {
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }

    return date;
  }

  isActiveAssessmentTap(isBasic = false, isFastTrack = false) {
    const pathname = this.props.location.pathname;
    const firstSegment = `/${pathname.split("/")[1]}`;

    const pathMatch =
      (isBasic && pathname.includes("/true/")) ||
      (isFastTrack && pathname.includes("/false/"));

    return (
      (firstSegment === "/establishmentAssessment" ||
        firstSegment === "/establishmentAssessmentInstruction") &&
      pathMatch
    );
  }

  render() {
    const { sidebarOpen, modalOpen } = this.state;
    const {
      children,
      location,
      establishments,
      overrideStyle,
      isLoading,
      establishmentProfile,
      withBreadcrumbs,
      breadcrumbLabel,
    } = this.props;
    const profileDisabled =
      location.pathname === "/establishmentProfile" ? "disable-link" : "";
    const assessmentDisabled =
      location.pathname === "/establishmentBasicDurationsPage"
        ? "disable-link"
        : "";
    const previousAssessmentsDisabled =
      location.pathname === "/establishmentDurationsPage" ? "disable-link" : "";
    const billsListDisabled =
      location.pathname === "/billsList" ? "disable-link" : "";
    const trainingDisabled =
      location.pathname === "/establishmentTrainings/trainings"
        ? "disable-link"
        : "";
    const fastTrackDisabled =
      location.pathname === "/establishmentFastTrackPage" ? "disable-link" : "";
    const isProfileFirstLogin =
      establishmentProfile.id && !establishmentProfile.is_confirmed;
    const disableLinkStyle = isProfileFirstLogin ? "disable-link" : "";

    return (
      <div className="establishment-container">
        {modalOpen && (
          <CollectionModal
            isOpen={modalOpen}
            collection={establishments}
            onConfirm={this.onEstablishmentChange}
            onClose={this.hideEstablishmentModal}
            isLoading={isLoading}
            isDisableClose={Object.keys(establishmentProfile).length <= 0}
          >
            {(establishment) => (
              <CollectionModalItem
                title={` ${establishment.establishment_name}`}
                text={[
                  {
                    title: "الرقم التسلسلي:",
                    text: ` (${establishment.labor_office_id} - ${establishment.sequence_id})`,
                  },
                  {
                    title: "النشاط:",
                    text: ` ${establishment.economic_activity_name}`,
                  },
                  {
                    title: "الحجم:",
                    text: `${establishment.size_name}`,
                  },
                ]}
                // isBlocked={establishment.is_blocked}
                assessmentStatus={establishment.assessments_status}
              />
            )}
          </CollectionModal>
        )}

        <header className="establishment-header ">
          <div className="establishment-header-wrapper">
            <div className="establishment-header-item">
              <div onClick={this.showSidebar} className="header-menu">
                <i className="fa fa-bars establishment-header-item__icon"></i>
              </div>
              {withBreadcrumbs && <Breadcrumbs label={breadcrumbLabel} />}
            </div>
            {establishmentProfile.id && !isProfileFirstLogin && (
              <div className="establishment-header-item  establishment-header-item__notification ">
                <HeaderNotification
                  history={this.props.history}
                  establishmentProfileId={establishmentProfile.id}
                />
              </div>
            )}
          </div>
        </header>
        <div className="establishment-content">
          <nav className={`establishment-sidebar ${sidebarOpen ? "open" : ""}`}>
            {sidebarOpen && (
              <div
                onClick={this.hideSidebar}
                className="establishment-sidebar-backdrop"
              />
            )}
            <div className="establishment-sidebar-avatar">
              {/* 
              <img
                className="establishment-sidebar-avatar__image"
                src={UserAvatar}
                alt="avatar"
              /> */}
              <div className="establishment-sidebar-avatar_establishment-name">
                <Text Tag="span" textType="roman" fontSize={"font-18"}>
                  {establishmentProfile.establishment_name}
                </Text>
                <button
                  onClick={this.showEstablishmentModal}
                  className="establishment-sidebar-avatar__switch-button"
                  id="switch-establishment-button"
                >
                  <i className="fa fa-refresh establishment-sidebar-avatar__switch-icon"></i>

                  <Text
                    Tag="span"
                    textType="roman"
                    fontSize={"font-15"}
                    className="establishment-sidebar-avatar__switch-label"
                  >
                    تبديل المنشأة
                  </Text>
                </button>
              </div>
            </div>
            <ul className="establishment-sidebar-list">
              <li className="establishment-sidebar-list__item">
                <div className="establishment-sidebar-list__item-wrapper">
                  <Link
                    className={`establishment-sidebar-list__item-link stroke ${disableLinkStyle}`}
                    to="/"
                    id="main-page-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconHome />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      الرئيسية
                    </Text>
                  </Link>
                </div>
              </li>
              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper ${
                    location.pathname === "/establishmentProfile"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`establishment-sidebar-list__item-link fill ${profileDisabled} ${disableLinkStyle}`}
                    to={"/establishmentProfile"}
                    id="establishment-profile-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconEstablishmentProfile />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      ملف المنشأة
                    </Text>
                  </Link>
                </div>
              </li>
              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper ${
                    location.pathname === "/establishmentBasicDurationsPage" ||
                    this.isActiveAssessmentTap(true, false)
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`establishment-sidebar-list__item-link stroke fill ${assessmentDisabled} ${disableLinkStyle}`}
                    to="/establishmentBasicDurationsPage"
                    id="assessment-list-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconSelfAssessment />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      التقييم الذاتي
                    </Text>
                  </Link>
                </div>
              </li>
              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper ${
                    location.pathname === "/establishmentFastTrackPage" ||
                    this.isActiveAssessmentTap(false, true)
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`establishment-sidebar-list__item-link stroke fill ${fastTrackDisabled} ${disableLinkStyle}`}
                    to="/establishmentFastTrackPage"
                    id="fastTrack-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconFastTrackAssessment />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      المسار المميز
                    </Text>
                  </Link>
                </div>
              </li>

              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper ${
                    location.pathname === "/establishmentDurationsPage"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`establishment-sidebar-list__item-link stroke fill ${previousAssessmentsDisabled} ${disableLinkStyle}`}
                    to="/establishmentDurationsPage"
                    id="assessment-previous-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconPreviousAssessment />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      التقييمات السابقة
                    </Text>
                  </Link>
                </div>
              </li>

              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper ${
                    location.pathname === "/establishmentTrainings/trainings"
                      ? "active"
                      : ""
                  }`}
                >
                  <Link
                    className={`establishment-sidebar-list__item-link stroke fill ${trainingDisabled} ${disableLinkStyle}`}
                    to="/establishmentTrainings/trainings"
                    id="training-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconTraining />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      التدريب
                    </Text>
                  </Link>
                </div>
              </li>
              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper ${
                    location.pathname === "/billsList" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`establishment-sidebar-list__item-link stroke fill ${billsListDisabled} ${disableLinkStyle}`}
                    to="/billsList"
                    id="bills-list-link"
                  >
                    <div className="establishment-sidebar-list__item-icon">
                      <IconBillsList />
                    </div>
                    <Text
                      Tag="span"
                      textType="roman"
                      fontSize={"font-15"}
                      className="establishment-sidebar-list__item-label"
                    >
                      الفواتير
                    </Text>
                  </Link>
                </div>
              </li>
              {/* <div className="separator-line-menu"></div> */}
              <li className="establishment-sidebar-list__item">
                <div
                  className={`establishment-sidebar-list__item-wrapper establishment-sidebar-logout`}
                >
                  <button
                    onClick={this.logOut}
                    className="establishment-sidebar-logout__button"
                    id="logout-button"
                  >
                    <div className="establishment-sidebar-logout__wrapper">
                      {/* <span className="establishment-sidebar-logout__icon fill stroke">
                        <IconLogout />
                      </span> */}
                      <i className="fa fa-sign-out establishment-sidebar-logout__icon"></i>
                      {/* <Text
                        Tag="span"
                        textType="roman"
                        fontSize={"font-15"}
                        // className="establishment-sidebar-list__item-label"
                      >
                        تسجيل الخروج
                      </Text> */}
                    </div>
                  </button>
                </div>
              </li>
            </ul>
          </nav>
          <main
            className="establishment-main"
            style={overrideStyle}
            onClick={() => {
              this.hideSidebar();
            }}
          >
            {children}
          </main>
        </div>
        <footer className="establishment-footer">
          <Text textType="h9" className="establishment-footer-copyright">
            جميع الحقوق محفوظة لبرنامج التقييم الذاتي © 2024
          </Text>
          <div className="flex items-center justify-center gap-x-2 accessiblity">
            <AccessiblityContainer />
          </div>
          <div className="flex items-center justify-center gap-x-2 footer-links">
            <Link className="stroke" to="/privacyPolicy" id="main-page-link">
              <Text
                Tag="div"
                textType="h9"
                isBold
                className="text-link-wrapper-inside-system"
              >
                سياسة الخصوصية
              </Text>
            </Link>
            <Link className="stroke" to="/faq" id="main-page-link">
              <Text
                Tag="div"
                textType="h9"
                isBold
                className="text-link-wrapper-inside-system"
              >
                الأسئلة الشائعة
              </Text>
            </Link>
          </div>

          <div className=" flex items-center justify-center gap-x-4 establishment-footer-row ">
            <a href="http://raqmi.dga.gov.sa/platforms/DigitalStamp/ShowCertificate/6500">
              <img
                className="establishment-footer-digital-stamp"
                src="http://raqmi.dga.gov.sa/platforms/DigitalStamp/GetStampFile/3672"
                alt="Digital Stamp"
              />
            </a>
            <img
              className="establishment-footer-logo-desktop"
              src={FooterLogo}
              alt="IMS MHRSD logo"
            />
          </div>
        </footer>
      </div>
    );
  }
}

PageWrapper.propTypes = {
  establishmentProfile: PropTypes.object.isRequired,
  userInfo: PropTypes.PropTypes.object,
  establishments: PropTypes.array.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  saveSelectedEstablishment: PropTypes.func.isRequired,
  overrideStyle: PropTypes.object,
  history: PropTypes.object,
  withBreadcrumbs: PropTypes.bool,
  breadcrumbLabel: PropTypes.string,
  children: PropTypes.node,
};

PageWrapper.defaultProps = {
  withBreadcrumbs: false,
  breadcrumbLabel: "",
};

const mapStateToProps = (state) => ({
  establishmentProfile: state.establishment.establishmentProfile,
  userInfo: state.auth.userInfo,
  establishments: state.establishment.establishments,
  isLogged: !!state.auth && state.auth.token !== null,
  isLoading: state.ajaxStatus.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  saveSelectedEstablishment: (establishment) =>
    dispatch(profileActions.loadEstablishmentProfile(establishment)),
  getEstablishments: () => dispatch(profileActions.loadEstablishments()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageWrapper));
