import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Text } from "../../../../shared-components/my-scope/atoms/text/index";
import "./CookieBanner.scss";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookiesAccepted");
    if (!isCookieAccepted) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="banner">
        <Text
          fontSize={"font-18"}
          textType="roman"
          mobileFontSize="font-15-mobile"
          className={"text"}
        >
          نحن نستخدم ملفات تعريف الارتباط لتقديم أفضل تجربة ممكنة على موقعنا.
          لمعرفة المزيد ، الرجاء زيارة
          <a
            className="stroke"
            href="https://ims.hrsd.gov.sa/#/privacyPolicy"
            id="main-page-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-link-wrapper-inside-system">
              {" "}
              سياسة الخصوصية{" "}
            </span>
          </a>
          . من خلال الاستمرار في استخدام هذا الموقع ، أو إغلاق هذه الرسالة، فإنك
          توافق على استخدامنا لملفات تعريف الارتباط.
        </Text>
        <button onClick={acceptCookies} className="button">
          قبول الكل
        </button>
      </div>
    )
  );
};

export default CookieBanner;
