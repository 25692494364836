import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import * as historyActions from "./actions/historyActions";

import Full from "./containers/Full/";

import CookieBanner from "./components/CookieBanner/CookieBanner"; // Import the cookie banner

import AboutPage from "./components/about/AboutPage";
import InteractiveGuidePage from "./components/interactiveguide/InteractiveGuidePage";
import FaQPage from "./components/faq/FaQPage";
import TermsPage from "./components/terms/TermsPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Page404 from "./views/Pages/Page404/";
import Page500 from "./views/Pages/Page500/";

import HomePage from "./components/home/HomePage";
import SpecialTrackPage from "./components/specialTrack/SpecialTrackPage";
import RedirectPage from "./components/redirectpage/RedirectPage";

import Unauthorized from "./views/Pages/unauthorized";
import EmptyEstablishments from "./views/Pages/emptyestablishments";
import ManageEstablishmentTrainingPage from "./components/training/ManageEstablishmentTrainingPage";
import Profile from "./views/Pages/Profile/Profile";
import SelfAssessmentList from "./views/Pages/SelfAssessmentList/SelfAssessmentList";
import SelfAssessmentResult from "./views/Pages/SelfAssessmentResult/SelfAssessmentResult";
import AssessmentPayment from "./views/Pages/AssessmentPayment/AssessmentPayment";
import CheckoutPage from "./views/Pages/Checkout/CheckoutPage";
import EstablishmentTrainingsPage from "./components/training/EstablishmentTrainingsPage";
import ManageEstablishmentAssessmentPage from "./components/establishmentprofile/ManageEstablishmentAssessmentPage";
import EstablishmentUnifiedAssessmentPage from "./components/establihmentUnifiedAssessment/EstablishmentUnifiedAssessmentPage";
import BillsList from "./views/Pages/BillsList/BillsList";
import EstablishmentTrainingDetailsPage from "./components/training/EstablishmentTrainingDetailsPage";
import EstablishmentTrainingRegister from "./components/training/EstablishmentTrainingRegister";
import BillInfo from "./views/Pages/BillInfo/BillInfo";
import NotificationsList from "./views/Pages/NotificationsList/NotificationsList";
import { keepTokenAlive } from "./components/common/Config";
import AssessmentInstructions from "./views/Pages/AssessmentInstructions/AssessmentInstructions";
import { fetchImsInfo } from "./actions/imsInfo";
import SignupInstructions from "./views/Pages/SignupInstructions/SignupInstructions";
import CheckoutResult from "./views/Pages/CheckoutResult/CheckoutResult";
import AssessmentSuccessSubmit from "./views/Pages/SelfAssessmentResult/AssessmentSuccessSubmit";
import BasicAssessment from "./views/Pages/SelfAssessment/BasicAssessment";
import FastTrackAssessment from "./views/Pages/SelfAssessment/FastTrackAssessment";
import { AssessmentContainer } from "./views/Pages/AssessmentFeature/index";

const App = ({ history }) => {
  const dispatch = useDispatch();

  const addLocation = useCallback(
    (location) => {
      dispatch(historyActions.addLocation(location));
    },
    [dispatch]
  );

  useEffect(() => {
    // dispatch(fetchImsInfo());

    history.listen((location, action) => {
      if (action === "PUSH") {
        addLocation(location.pathname);
      }
    });
  }, []);
  keepTokenAlive();
  return (
    <>
    {/* Render Cookie Banner */}
  <CookieBanner />

  {/* Main app routes */}

    <Switch>
      <Route path="/home" name="Home" component={HomePage} />
      <Route
        path="/specialTrack"
        name="Special Track Page"
        component={SpecialTrackPage}
      />
      <Route exact path="/about" name="About Page" component={AboutPage} />
      <Route exact path="/faq" name="F&Q Page" component={FaQPage} />
      <Route
        exact
        path="/terms"
        name="Terms And Conditions Page"
        component={TermsPage}
      />
      <Route
        path="/interactiveGuide"
        name="Interactive Guide Page"
        component={InteractiveGuidePage}
      />
      <Route
        exact
        path="/privacyPolicy"
        name="Privacy Policy"
        component={PrivacyPolicy}
      />
      <Route
        path="/signupInstructions"
        name="signupInstructions"
        component={SignupInstructions}
      />
      <Route path="/billInfo/:billId" name="bill info" component={BillInfo} />
      <Route
        exact
        path="/Unauthorized"
        name="Page 500"
        component={Unauthorized}
      />
      <Route
        exact
        path="/EmptyEstablishments"
        name="Empty Establishments"
        component={EmptyEstablishments}
      />
      <Route exact path="/404" name="Page 404" component={Page404} />
      <Route exact path="/500" name="Page 500" component={Page500} />
      <Route
        path="/establishmentProfile/:section?"
        name="establishmentProfile"
        component={Profile}
      />
      <Route
        path="/establishmentDurationsPage"
        name="establishmentDurationsPage"
        component={SelfAssessmentList}
      />
      <Route
        path="/establishmentBasicDurationsPage"
        name="establishmentBasicDurationsPage"
        component={BasicAssessment}
      />
      <Route
        path="/establishmentFastTrackPage"
        name="establishmentFastTrackPage"
        component={FastTrackAssessment}
      />

      <Route
        path="/establishmentUnifiedAssessment/:periodId/:assessmentId/:establishmentId/:assessment_version/:period_assessment_id"
        name="establishmentUnifiedAssessment"
        component={EstablishmentUnifiedAssessmentPage}
      />
      <Route
        path="/establishmentTrainings/:section?"
        name="establishmentTrainings"
        component={EstablishmentTrainingsPage}
      />
      <Route
        path="/establishmentAssessmentResult/:periodId/:assessmentId/:establishmentId/:assessmentVersion"
        name="EstablishmentAssessmentResult"
        component={SelfAssessmentResult}
      />
      <Route
        path="/assessmentSuccessSubmit/:periodId/:assessmentId/:establishmentProfileId/:assessmentVersion"
        name="assessmentSuccessSubmit"
        component={AssessmentSuccessSubmit}
      />
      <Route
        path="/establishmentAssessmentPayment/:periodId/:assessmentId/:establishmentId/:assessmentVersion/:withoutResult/:serviceType/:period_assessment_id/:assessmentType"
        name="EstablishmentAssessmentPayment"
        component={AssessmentPayment}
      />
      <Route
        path="/establishmentAssessmentPayment/:periodId/:assessmentId/:establishmentId/:assessmentVersion/:withoutResult/:serviceType/:period_assessment_id"
        name="EstablishmentAssessmentPayment"
        component={AssessmentPayment}
      />

      <Route
        path="/checkout/:checkout_id/:service_type/:sadad_number/:bill_number"
        name="Checkout"
        component={CheckoutPage}
      />
      <Route path="/checkout" name="Checkout" component={CheckoutPage} />

      <Route
        path="/checkoutResult/:service_type/:training_id/:training_status"
        name="checkoutResult"
        component={CheckoutResult}
      />
      <Route path="/billsList" name="billsList" component={BillsList} />
      <Route
        path="/establishmentAssessment/:id/:assessment_version/:isBasicAssessment/:periodAssessmentId"
        name="ViewEstablishmentAssessment"
        // component={ManageEstablishmentAssessmentPage}
        component={AssessmentContainer}
      />
      <Route
        path="/establishmentAssessmentInstruction/:id/:assessment_version/:isBasicAssessment/:periodAssessmentId"
        name="ViewEstablishmentAssessmentInstructions"
        component={AssessmentInstructions}
      />
      <Route
        path="/establishmentFastTrackResult"
        name="establishmentFastTrackResult"
        component={Full}
      />
      <Route
        path="/establishmentTraining/:establishment_id/:id/:status"
        name="establishmentTrainingEdit"
        component={ManageEstablishmentTrainingPage}
      />
      <Route
        path="/establishmentTrainingDetails/:establishment_id/:id"
        name="establishmentTrainingEdit"
        component={EstablishmentTrainingDetailsPage}
      />
      <Route
        path="/establishmentTrainingRegister/:establishmentId/:trainingId/:status"
        name="establishmentTrainingRegister"
        component={EstablishmentTrainingRegister}
      />
      <Route
        path="/notifications"
        name="notifications"
        component={NotificationsList}
      />
      <Route
        path="/assessmentDurations"
        name="assessmentDurations"
        component={Full}
      />
      <Route path="/success_payment" name="successPayment" component={Full} />
      <Route path="/failed_payment" name="failedPayment" component={Full} />
      <Route path="/redirect*" name="Redirect Page" component={RedirectPage} />
      <Redirect from="/" to="/home" />
      <Route path="*" name="Not Found" component={Page500} />
    </Switch>
    </>
  );
};

export default withRouter(App);
