import React from "react";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";

const PrivacyPolicyModal = ({ isOpen, onRequestClose, onAccept }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Privacy Policy" className="modal-content">
      <ModalHeader>سياسة الخصوصية </ModalHeader>

      <ModalBody>
        <p>
          أوافق على  سياسة الخصوصية.
          لمزيد من المعلومات يمكنك الاطلاع على
          <a
            href="https://ims.hrsd.gov.sa/#/privacyPolicy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            {' '}سياسة الخصوصية
          </a>.
        </p>
      </ModalBody>

      <ModalFooter>
        <Row>
          <Col>
            <Button
              className="btn btn-round"
              color="success"
              onClick={onAccept}
            >
              أوافق
            </Button>
          </Col>
          <Col>
            <Button
              className="btn btn-round"
              color="danger"
              onClick={onRequestClose}
            >
              إلغاء
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default PrivacyPolicyModal;
